import React from 'react';
import { Link } from 'react-router-dom';

import { useUser } from '../providers/UserProvider';

export const LoginIcon: React.FC = () => {
  const { userDetails } = useUser();
  return (
    <Link to="/account">
      <img
        src={
          userDetails?.loggedIn && userDetails?.isPhotoValid
            ? userDetails.photoURL
            : '/images/icons/user.png'
        }
        alt="User"
        height="26"
        width="26"
        className="mt-0.5 rounded-full"
      />
    </Link>
  );
};
