type CommandArray = Array<(this: typeof globalThis) => void> | undefined;

declare global {
  interface Window {
    adngin?: {
      adnginLoaderReady?: boolean;
      cmd: {
        startAuction: (ads: string[]) => void;
      };
      queue?: CommandArray;
    };
    destroyRefreshTopAd?: (state: boolean) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    gtag?: any;
  }
}

export function destroyTopAd(state: boolean) {
  if (typeof window.destroyRefreshTopAd === 'function') {
    try {
      window.destroyRefreshTopAd(state);
    } catch (e) {
      console.error(e);
    }
  }
}

export function refreshAd() {
  setTimeout(() => {
    if (
      window.adngin !== undefined &&
      window.adngin.cmd !== undefined &&
      typeof window.adngin.cmd.startAuction === 'function'
    ) {
      window.adngin.cmd.startAuction(['sidebar_right']);
    }
  }, 50);
}

export function refreshTopAd(state: boolean) {
  if (!state) return;
  if (
    window.adngin !== undefined &&
    window.adngin.cmd !== undefined &&
    window.adngin.adnginLoaderReady &&
    typeof window.adngin.cmd.startAuction === 'function'
  ) {
    window.adngin.cmd.startAuction(['top_banner']);
  }
}

export function refreshCompleteAd() {
  console.log('refresh 300x250 ad');
  if (
    window.adngin !== undefined &&
    window.adngin.cmd !== undefined &&
    typeof window.adngin.cmd.startAuction === 'function'
  ) {
    window.adngin.cmd.startAuction(['end_mobile']);
  }
}

async function detectBraveBrowser(): Promise<boolean> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if ((navigator as any).brave) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (navigator as any).brave
      .isBrave()
      .then((isBrave: boolean) => isBrave);
  }
  return false;
}

export async function detectAdBlocker(): Promise<boolean> {
  const isBrave = await detectBraveBrowser();
  if (isBrave) return true;

  return new Promise((resolve) => {
    let detected = false;

    // 1. attempt to load an ad script
    const testScript = document.createElement('script');
    testScript.src =
      'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';
    testScript.type = 'text/javascript';
    testScript.async = true;

    testScript.onerror = () => {
      if (!detected) {
        detected = true;
        resolve(true);
      }
    };

    document.body.appendChild(testScript);

    // 2. check if the request was actually made
    setTimeout(() => {
      const entries = performance.getEntriesByType('resource');
      const adRequest = entries.find((entry) =>
        entry.name.includes('pagead2.googlesyndication.com'),
      );

      if (!adRequest && !detected) {
        detected = true;
        resolve(true);
      }

      document.body.removeChild(testScript);

      // no ad blocker detected
      if (!detected) {
        resolve(false);
      }
    }, 2000);
  });
}