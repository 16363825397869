import { Translations } from '../translations.types';

export const eu: Translations = {
  flag: 'Bandera',
  shape: 'Forma',
  'Population & Currency': 'Biztanleria eta Moneta',
  'Capital flag': 'Hiriburua',
  emblem: 'Ikurra',
  neighbours: 'Auzokideak',
  placeholder: 'Herrialdea\u200b, lurraldea...',
  guess: 'Asmatu',
  share: 'Emaitzak partekatu',
  'unknown-country': 'Herrialde ezezaguna!',
  copy: 'Emaitzak arbelean kopiatu dira',
  'play-bonus': 'Bonus txanda jolastu',
  'next-bonus': 'Hurrengo bonus txanda',
  'other-games': 'Gure beste jokoak:',
  'submit-feedback': 'Iritzia bidali',
  'remaining-guesses': '{{attemptsLeft}} saio geratzen dira',
  main: {
    'make-a-guess': 'Lehenengo fitxa agerian uzteko asma ezazu',
    country: 'Herrialdea...',
    flag: 'Bandera',
    'already-guessed': 'Dagoeneko {{currentGuess}} asmatu duzu',
  },
  quiz: {
    title: 'Biztanleria eta Moneta',
    'estimated-population':
      '{{countryName}}-ren kalkulatutako biztanleria zein da?',
    'guess-currency': 'Zein da {{countryName}}-n erabiltzen den moneta?',
    population: 'Biztanleria',
    currency: 'Moneta',
    micro: {
      'less-25': '25 baino gutxiago',
      over: '2000 baino gehiago',
    },
    small: {
      'first-option': '< 50 mila',
      'second-option': '50 - 500 mila',
      'third-option': '500k - 1 milioi',
      'fourth-option': '1+ milioi',
    },
    medium: {
      'first-option': '< 3 milioi',
      'second-option': '3 - 5 milioi',
      'third-option': '5 - 8 milioi',
      'fourth-option': '8+ milioi',
    },
    large: {
      'first-option': '< 15 milioi',
      'second-option': '15 - 25 milioi',
      'third-option': '25 - 35 milioi',
      'fourth-option': '35+ milioi',
    },
    massive: {
      'first-option': '< 60 milioi',
      'second-option': '60 - 80 milioi',
      'third-option': '80 - 100 milioi',
      'fourth-option': '100+ milioi',
    },
  },
  'shape-round': {
    title: '{{countryName}}-ren forma zein da?',
    sponsored: 'Babestua:',
    like: 'Txanda hau gustatzen zaizu',
    try: 'Saiatu',
  },
  'capital-flag': {
    title: '{{country}}-ko hiriburuko bandera aukeratu: {{city}}',
  },
  'emblem-round': {
    title: '{{countryName}}-ren ikur nazionala hautatu',
  },
  border: {
    title: '{{countryName}}-ren auzokide baten bandera hautatu',
  },
  settings: {
    title: 'Ezarpenak',
    'distance-unit': 'Distantzia unitatea',
    theme: 'Gaia',
    language: 'Hizkuntza',
    'difficulty-modifiers': 'Zailtasun aldagailuak',
    grayscale: 'Grayscale bandera',
  },
  stats: {
    title: 'Estatistikak',
    played: 'Jokatua',
    win: 'Irabazte ehunekoa',
    currentStreak: 'Egungo segida',
    maxStreak: 'Gehieneko segida',
    guessDistribution: 'Asmakizunen banaketa',
    donate: '🇺🇦 Eman Ukrainari ❤️',
  },
  'how-to-play': {
    title: 'Nola jolastu',
    guess: 'Asmatu',
    'number-guesses': '6 saiakeratan.',
    'valid-country':
      'Asmakizun bakoitza herrialde edo lurralde baliozkoa izan behar da.',
    'after-guess':
      'Asmakizun bakoitzaren ondoren, helmugara arteko distantzia, norabidea eta gertutasuna ikusiko dituzu.',
    examples: 'Adibideak',
    'your-guess': 'Zure asmakizuna',
    is: 'da',
    away: 'helmugatik urrun dago, ipar-ekialdean kokatuta dago eta gertutasuna %32koa da urruti dagoelako.',
    'second-guess': 'Zure bigarren asmakizuna',
    'getting-closer':
      'gerturatzen ari da! {{distance}} urrun, hego-ekialdean kokatuta eta %83 gertutasuna!',
    'next-guess': 'Hurrengo asmakizuna,',
    'right-guess': 'hau da asmatzeko helmuga! Zorionak',
    'new-game': 'Berri bat',
    available: 'egunero eskuragarri egongo da!',
    suggestions: 'Galderarik edo iradokizunik?',
    check: 'Begiratu',
    faq: 'Flagle FAQ',
    made: 'Egilea',
    ads: 'Iragarkiak:',
    privacy: 'Pribatutasun politika',
    feedback: 'Iritzia',
    hiring: 'Langileak bilatzen ari gara',
  },
  faq: {
    'when-new-game-available': {
      question: 'Noiz dago eskuragarri joko berria?',
      answer:
        'Joko berri bat egunero eskuragarri dago zure gailuaren tokiko orduan, 0:00etan!',
    },
    'how-earn-bonus-round': {
      question: 'Nola irabazi hurrengo bonus txandara pasatzeko aukera?',
      answer:
        'Bonus txanda 1 (forma) lortzeko, herrialdea ondo asmatzea besterik ez duzu behar.',
    },
    'why-data-incorrect': {
      question: 'Zergatik daude zure datuak okerrak edo zaharkituak?',
      answer:
        'Gure datuak ahalik eta azkarren eta zehatzenean eguneratzen saiatzen ari gara. Badakigu mugak aldatzen direla, hiriburuak mugitzen direla eta mapak zaharkitu egiten direla. Akats guztiak erabat nahigabeak dira eta ez dute inolako adierazpen politikorik. Eskertzen dugu zure iritzia eta akatsak konponduko ditugu horien berri izaten dugun heinean. Denborarekin, gero eta gutxiago egongo da zuzentzeko.',
    },
    'green-yellow-squares': {
      question: 'Zer esan nahi dute karratu berde eta horiek?',
      answer:
        'Hurbiltasun ehunekoarekin lotuta daude:<br><br>• 🟩 = 20%<br>• 🟨 = 10%',
    },
    'proximity-percent': {
      question: 'Zer da hurbiltasun ehunekoa?',
      answer:
        'Distantzia adierazteko beste modu bat da.<br><br>Zure asmakizuna munduaren beste aldean badago, 0% jasoko duzu.<br>Helburuan bertan badago, 100% jasoko duzu!',
    },
    'how-distances-computed': {
      question: 'Nola kalkulatzen dira distantziak?',
      answer:
        'Erakutsitako distantziak hautatutako lurraldearen eta helburuaren erdiguneen arteko distantziak dira.<br><br>Adibidez, Estatu Batuen eta Kanadaren arteko kalkulatutako distantzia 2260 km ingurukoa da, nahiz eta mugakide izan.<br><br>Hautaketa horretarako bi arrazoi:<br><br>• Teknika aldetik askoz errazagoa da distantzia horrela kalkulatzea, muga hurbilenak erabili beharrean.<br><br>• Helburuko herrialdearen tamainari buruzko informazioa ondorioztatu dezakezu informazio honi esker.',
    },
    'why-not-valid-answer': {
      question: 'Zergatik ez da herrialde jakin bat baliozko erantzuna?',
      answer:
        'ISO 3166-1 estandarra erabiltzen dut herrialde kodeen zerrendarako erreferentzia gisa.<br><br>Adibidez, Eskozia ez da baliozko erantzuna, estandar horren arabera, herrialde hori Erresuma Batuaren parte baita.',
    },
    'how-countries-picked': {
      question:
        'Nola aukeratzen dira herrialdeak eta lurraldeak? Zer gertatuko da herrialde guztiak aukeratu direnean?',
      answer:
        'Egunero, herrialde edo lurralde bat ausaz aukeratzen da!<br><br>Beraz, egunero ausaz bat aukeratzen jarraituko du, betiko!<br><br>Bi arau gehitu dira:<br><br>• Ez du 5.000 km² baino gutxiagoko herrialde edo lurralderik aukeratzen azken 7 egunetan bat aukeratu bada.<br>• Ez du azken 100 egunetan aukeratutako herrialde bat aukeratzen.<br>• Ez du atzoko kontinente bereko herrialderik aukeratzen (Afrika eta Asia bakarrik aplikatzen da).',
    },
    'why-ads': {
      question: 'Zergatik ikusten ditut iragarkiak nire gailuan?',
      answer:
        'Iragarkiak erabiltzen ditugu funtzionalitate berrien garapen kostuak, baita ostalaritza eta bestelako gastuak ere, ordaintzeko. Denborarekin, @teuteuf-ek iradokizun eta eskaera asko jaso ditu eta ezin izan ditu guztiak bete. Zuei ezaugarri gehiago eta joko dibertigarriagoa ekarri nahi dizkizuegu. Gure plana aplikazio mugikor ofizial bat eta bonus ezaugarri ugari ekartzea da, Flagle Interneteko geografia quiz jokoa dibertigarriena izaten jarraitzeko!',
    },
  },
  account: {
    title: 'Kontua',
    loggedIn: {
      hi: 'Kaixo',
      text: 'Orain zure estatistikak gordetzen ditugu partida bakoitzaren ondoren. Horrek zure aurrerapena babesten du, baina gailu anitzetan jokatzeko aukera ematen du.',
      learnMore: 'Gehiago ikasi',
      signOut: 'Amaitu saioa',
      manageAccount: 'Kudeatu kontua',
    },
    loggedOut: {
      text: 'Erregistratu Teuteuf Games kontu batean edo hasi saioa zure joko-estatistikak gordetzen hasteko eta hainbat gailutan jolasten hasteko. Onura guztiak ezagutu nahi dituzu?',
      discoverMoreHere: 'Ezagutu gehiago hemen',
      signUp: 'Izena eman',
      login: 'Saioa hasi',
      loginWith: 'Saioa hasi {{platform}}',
    },
  },
};
