import './App.css';
import 'react-toastify/dist/ReactToastify.css';

import Button from '@mui/material/Button';
import { lazy, Suspense, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { Flip, ToastContainer } from 'react-toastify';
import styled from 'styled-components';

import { AdnginInContent } from './components/AdnginInContent';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import AntiAdBlockerComponent from './components/AntiAdBlockerToast';
import EmojiRender from './components/EmojiRender';
import FaqSection from './components/FaqSection';
import { HowToLinkIcon } from './components/HowToLinkIcon';
import { IFrameWarningToast } from './components/IFrameWarningToast';
import { LoginIcon } from './components/LoginIcon';
import RoundsNavigation from './components/RoundsNavigation';
import { SettingsLinkIcon } from './components/SettingsLinkIcon';
import { StatsLinkIcon } from './components/StatsLinkIcon';
import { TeuteufGamesLinkIcon } from './components/TeuteufGamesLinkIcon';
import { TitleBarDiv } from './components/Title';
import { getDayString } from './hooks/useDailySeed';
import { useMainGameCompleted } from './hooks/useMainGameCompleted';
import { useSettings } from './hooks/useSettings';
import { TodaysCountryProvider } from './providers/TodaysCountryProvider';
import { useUser } from './providers/UserProvider';
import AccountRoute from './routes/AccountRoute';
import { MainGameRoute } from './routes/MainGameRoute/MainGameRoute';
import RulesRoute from './routes/RulesRoute';
import { SettingsRoute } from './routes/SettingsRoute';
import { StatsRoute } from './routes/StatsRoute';
import { TeuteufGamesRoute } from './routes/TeuteufGamesRoute';
import { detectAdBlocker } from './utils/ads';
import { inIFrame } from './utils/inIFrame';

// eslint-disable-next-line @typescript-eslint/no-var-requires

const GameButton = styled(Button)`
  span {
    font-weight: bold;
    text-transform: none;
  }
`;

const GamesContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 5px;
`;

const LazyFirstBonusRoundRoute = lazy(() =>
  import(
    /* webpackChunkName: "FirstBonusRoundRoute", webpackPreload: true */ './routes/ShapeGameRoute/ShapeGameRoute'
  ).then((module) => ({
    default: module.ShapeGameRoute,
  })),
);

const LazySecondBonusRoundRoute = lazy(() =>
  import(
    /* webpackChunkName: "LazySecondBonusRoundRoute", webpackPreload: true */ './routes/NationalEmblemGameRoute'
  ).then((module) => ({
    default: module.CoatOfArmsGameRoute,
  })),
);

const LazyThirdBonusRoundRoute = lazy(() =>
  import(
    /* webpackChunkName: "LazyThirdBonusRoundRoute", webpackPreload: true */ './routes/CapitalFlagGameRoute'
  ).then((module) => ({
    default: module.CapitalFlagGameRoute,
  })),
);

const LazyFourthBonusRoundRoute = lazy(() =>
  import(
    /* webpackChunkName: "FourthBonusRoundRoute", webpackPreload: true */ './routes/BorderFlagGameRoute'
  ).then((module) => ({
    default: module.BorderFlagGameRoute,
  })),
);

const LazyFifthBonusRoundRoute = lazy(() =>
  import(
    /* webpackChunkName: "FifthBonusRoundRoute", webpackPreload: true */ './routes/QuizGameRoute/QuizGameRoute'
  ).then((module) => ({
    default: module.QuizGameRoute,
  })),
);

/**** MJD - ADDED THIS TO SUPPORT BONUS ROUND FIX *****/

const startDate = getDayString();

window &&
  window.setInterval(() => {
    const currentDate = getDayString();
    if (startDate !== currentDate) {
      window.dispatchEvent(new CustomEvent('date-changed'));
    }
  }, 1000);

const refreshPage = () => {
  window && (window.location.href = '/');
};

/**** MJD - ADDED THIS TO SUPPORT BONUS ROUND FIX END  *****/

export function App() {
  const [showIFrameWarning, setShowIFrameWarning] = useState<boolean>(false);
  const mainGameCompleted = useMainGameCompleted();
  const [settings] = useSettings();
  const location = useLocation();
  const { t } = useTranslation();
  const { userDetails, isLoaded } = useUser();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [adBlockerDetected, setAdBlockerDetected] = useState<boolean>(false);

  useEffect(() => {
    if (!isLoaded) return;

    const checkAdBlocker = async () => {
      setAdBlockerDetected(await detectAdBlocker());
    };

    if (!userDetails.premiumGames?.includes('flagle')) {
      // Load ads
      const scriptTag = document.createElement('script');
      scriptTag.src = 'https://cdn.snigelweb.com/adengine/flagle.io/loader.js';
      scriptTag.id = 'snigel';
      scriptTag.async = true;
      scriptTag.setAttribute('data-cfasync', 'false');
      scriptTag.type = 'text/javascript';
      scriptTag.addEventListener('load', () => {
        let breakpoint = 'xsmall';
        const w = window.innerWidth;

        breakpoint = w >= 768 ? 'small' : breakpoint;
        breakpoint = w >= 960 ? 'medium' : breakpoint;
        breakpoint = w >= 1280 ? 'large' : breakpoint;
        breakpoint = w >= 1400 ? 'xlarge' : breakpoint;

        /* eslint-disable @typescript-eslint/no-explicit-any */
        const googletag = (window as any).googletag;
        googletag.cmd.push(() => {
          googletag.pubads().set('page_url', 'flagle.io');
          googletag
            .pubads()
            .addEventListener('slotRenderEnded', function (event: any) {
              const slot_id = event.slot.getSlotElementId();
              if (slot_id === 'adngin-top_banner-0') {
                document.documentElement.classList.add('snigel-top-loaded');
                document.documentElement.classList.add(
                  'snigel-' + breakpoint.toLowerCase(),
                );
              }
            });
        });
      });
      document.body.appendChild(scriptTag);

      // check ad block
      checkAdBlocker();
    }
  }, [isLoaded, userDetails]);

  const onRoundPage =
    location.pathname === '/' || location.pathname.includes('bonus-round');

  /**** MJD - ADDED THIS TO SUPPORT BONUS ROUND FIX *****/
  useEffect(() => {
    window.addEventListener('date-changed', refreshPage);
    return () => {
      window.removeEventListener('date-changed', refreshPage);
    };
  }, []);
  /**** MJD - ADDED THIS TO SUPPORT BONUS ROUND FIX END *****/

  useEffect(() => {
    const prefersDark = window.matchMedia(
      '(prefers-color-scheme: dark)',
    ).matches;
    if (
      settings.theme === 'dark' ||
      (typeof settings.theme === 'undefined' && prefersDark)
    ) {
      document.documentElement.classList.add('dark');
      document.body.classList.add('bg-[#020617]');
    } else {
      document.documentElement.classList.remove('dark');
      document.body.classList.remove('bg-[#9da2b6]');
    }
  }, [settings]);

  useEffect(() => {
    if (inIFrame()) {
      setShowIFrameWarning(true);
      window.gtag && window.gtag('event', 'iframe_popup');
    }
  }, []);

  return (
    <div className="App dark:text-white">
      <ToastContainer
        hideProgressBar
        position="top-center"
        transition={Flip}
        autoClose={false}
      />
      <IFrameWarningToast show={showIFrameWarning} />
      {/* <AntiAdBlockerComponent adBlockerDetected={adBlockerDetected} /> */}
      <div className="m-0 overflow-auto p-0 w-full h-full flex justify-start items-center flex-col App-center lg:bg-[#f3f4f6] dark:bg-[#0f172a] lg:dark:bg-[#020617]">
        <div className="w-full flex h-full flex-col max-w-lg justify-start items-center relative pb-20 lg:pb-32 lg:box-content lg:px-12 lg:bg-white lg:dark:bg-slate-900 lg:shadow-2xl">
          <TodaysCountryProvider>
            <div
              className="max-w-[512px] max-h-[65px] gap-2 dark:text-white border-b-2 px-3 border-gray-200 justify-center items-center flex flex-shrink w-full"
              style={{
                gridTemplateColumns: '30px 30px 1fr 30px 30px',
              }}
            >
              <TitleBarDiv>
                <LoginIcon />
              </TitleBarDiv>
              <TitleBarDiv>
                <HowToLinkIcon />
              </TitleBarDiv>
              <div className="w-[23px]"></div>
              <h1 className="text-3xl md:text-4xl font-bold uppercase tracking-wide text-center my-1 flex-auto">
                <span className="inline-flex flex-row justify-center items-center">
                  <EmojiRender
                    text="🌎"
                    className="text-2xl md:text-3xl mr-2"
                  />
                  Flag<span style={{ color: '#1a76d2' }}>le</span>
                </span>
              </h1>
              <TitleBarDiv>
                <TeuteufGamesLinkIcon />
              </TitleBarDiv>
              <TitleBarDiv>
                <StatsLinkIcon />
              </TitleBarDiv>
              <TitleBarDiv>
                <SettingsLinkIcon />
              </TitleBarDiv>
            </div>

            {onRoundPage && <RoundsNavigation />}

            <Suspense fallback="loading next bonus round…">
              <Switch>
                <Route exact path="/">
                  <MainGameRoute />
                </Route>

                <Route exact path="/logged-in"></Route>

                <Route exact path="/bonus-round/1">
                  {mainGameCompleted ? (
                    <LazyFirstBonusRoundRoute />
                  ) : (
                    <Redirect to="/" />
                  )}
                </Route>

                <Route exact path="/bonus-round/2">
                  {mainGameCompleted ? (
                    <LazySecondBonusRoundRoute />
                  ) : (
                    <Redirect to="/" />
                  )}
                </Route>

                <Route exact path="/bonus-round/3">
                  {mainGameCompleted ? (
                    <LazyThirdBonusRoundRoute />
                  ) : (
                    <Redirect to="/" />
                  )}
                </Route>

                <Route exact path="/bonus-round/4">
                  {mainGameCompleted ? (
                    <LazyFourthBonusRoundRoute />
                  ) : (
                    <Redirect to="/" />
                  )}
                </Route>

                <Route exact path="/bonus-round/5">
                  {mainGameCompleted ? (
                    <LazyFifthBonusRoundRoute />
                  ) : (
                    <Redirect to="/" />
                  )}
                </Route>

                <Route exact path="/settings">
                  <SettingsRoute />
                </Route>

                <Route exact path="/stats">
                  <StatsRoute />
                </Route>

                <Route exact path="/rules">
                  <RulesRoute />
                </Route>

                <Route exact path="/account">
                  <AccountRoute />
                </Route>

                <Route exact path="/teuteuf-games">
                  <TeuteufGamesRoute />
                </Route>
              </Switch>
            </Suspense>
          </TodaysCountryProvider>

          {onRoundPage && <AdnginInContent />}

          <div className="w-full mt-auto mb-2.5 bottom-0 flex justify-center flex-col items-center gap-2.5 dark:text-white">
            <a
              href="https://teuteuf.notion.site/17eeff4e9f3880e2a6aeef075e74939b?pvs=105"
              target="_blank"
              rel="noreferrer"
              style={{ marginTop: '15px', textDecoration: 'underline' }}
            >
              {t('submit-feedback')}
            </a>
            <div className="flex gap-1 justify-center items-center mt-2 w-full">
              <a
                href="https://www.facebook.com/teuteufgames/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="/images/icons/facebook.png"
                  width={36}
                  height={36}
                  alt="facebook"
                />
              </a>
              <a
                href="https://www.tiktok.com/@teuteufgames"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="/images/icons/tiktok.svg"
                  width={36}
                  height={36}
                  alt="tiktok"
                  className="w-8 h-8 mr-1 dark:invert"
                />
              </a>
              <a
                href="https://www.instagram.com/teuteufgames"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="/images/icons/instagram.png"
                  width={31}
                  height={31}
                  alt="instagram"
                />
              </a>
            </div>
            <div>{t('other-games')}</div>
            <GamesContainer>
              <GameButton
                variant="outlined"
                onClick={() => {
                  window.open('https://whentaken.com/');
                }}
              >
                &nbsp;
                <span>🕑 Whentaken</span>
              </GameButton>
              <GameButton
                variant="outlined"
                onClick={() => {
                  window.open('https://worldle.teuteuf.fr');
                }}
              >
                <EmojiRender text="🌍" className="inline-block" />
                &nbsp;
                <span>Worldle</span>
              </GameButton>
              <GameButton
                variant="outlined"
                onClick={() => {
                  window.open('https://wheretakenusa.teuteuf.fr');
                }}
              >
                <EmojiRender text="🌍" className="inline-block" />
                &nbsp;
                <span>WhereTaken</span>
              </GameButton>
            </GamesContainer>
          </div>
          <FaqSection />
        </div>

        <AdWrapperWrapper className="snigel-sidev">
          <AdWrapper>
            <div id="adngin-sidebar_left-0"></div>
          </AdWrapper>
        </AdWrapperWrapper>
      </div>
      <div id="adngin-video-0"></div>
      <div id="adngin-top_banner-0"></div>
    </div>
  );
}

const AdWrapperWrapper = styled('div')`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 200;
`;

const AdWrapper = styled('div')`
  position: sticky;
  padding: 20px 40px 20px 20px;
  pointer-events: all;
`;
