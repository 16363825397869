export interface UserDetails {
  loggedIn: boolean;
  firstName: string;
  photoURL: string;
  isPhotoValid: boolean;
  marketingOptIn: string;
  premiumGames: string[];
  subscription: {
    autoRenew: boolean;
    isPremium: boolean;
    subscriptionEndDate: string;
  };
}

export const initialUserDetailsState: UserDetails = {
  loggedIn: false,
  firstName: '',
  photoURL: '',
  isPhotoValid: false,
  marketingOptIn: '',
  premiumGames: [],
  subscription: {
    autoRenew: false,
    isPremium: false,
    subscriptionEndDate: 'null',
  },
};
