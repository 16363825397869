import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { co, de, en, es, eu, fr, hu, ja, nl, pl, pt } from './locales';

export const translations = {
  en: { translation: en },
  fr: { translation: fr },
  es: { translation: es },
  pt: { translation: pt },
  co: { translation: co },
  de: { translation: de },
  eu: { translation: eu },
  hu: { translation: hu },
  ja: { translation: ja },
  nl: { translation: nl },
  pl: { translation: pl },
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: translations,
    interpolation: {
      escapeValue: false,
    },
    fallbackLng: 'en',
  });

export default i18n;
