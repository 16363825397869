import { RoundNavigation } from '@teuteuf-games/round-navigation';
import { useTranslation } from 'react-i18next';

import { useTodaysCountry } from '../providers/TodaysCountryProvider';
import transMe from '../utils/navigationWords';

export default function RoundsNavigation() {
  const { rounds, completed, currentRound, navToRound } = useTodaysCountry();
  const { i18n } = useTranslation();

  if (!completed.includes('Flag')) return <div className="flex h-5"></div>;

  return (
    <div className="flex w-full justify-center px-[2px] md:px-0">
      <RoundNavigation
        rounds={rounds}
        currentRound={currentRound}
        getRoundEmojiString={(round) => round.emojis}
        getRoundName={(round) =>
          transMe(i18n.resolvedLanguage ?? 'en', round.name)
        }
        getRoundCompleted={(round) => completed.includes(round.name)}
        getRoundIsNew={() => false}
        goToRound={(round) => navToRound(round)}
      />
    </div>
  );
}
