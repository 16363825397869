import { useTranslation } from 'react-i18next';
import { styled } from 'styled-components';

import { BackButton } from '../components/BackButton';
import { useUser } from '../providers/UserProvider';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  gap: 12px;
  padding-left: 12px;
  padding-right: 12px;
  margin-top: 20px;
`;

export const AccountRoute = () => {
  const { t } = useTranslation();

  const { userDetails, referer, logout } = useUser();

  const authUrl =
    window?.location.hostname === 'localhost'
      ? 'http://localhost:3000/auth'
      : 'https://auth.teuteuf.fr/auth';

  const socialLogins = [
    {
      name: 'X',
      path: 'twitter',
      icon: '/images/icons/X.svg',
      className: 'bg-black text-white',
    },
    {
      name: 'Google',
      path: 'google',
      icon: '/images/icons/google.png',
      className: 'bg-[#4285f4] text-white',
    },
    {
      name: 'Apple',
      path: 'apple',
      icon: '/images/icons/apple.png',
      className: 'bg-black text-white',
    },
  ];

  return (
    <Container>
      <BackButton />

      <div className="space-y-3 text-justify border-b-2 border-gray-200 pb-3 mb-3">
        <h3 className="text-xl font-bold mb-3">{t('account.title')}</h3>
        {!userDetails.loggedIn && (
          <div>
            <p className="mt-3">
              {t('account.loggedOut.text')}
              <a
                href="https://account.teuteuf.fr"
                className="text-blue-500 dark:text-blue-400 hover:text-blue-400 cursor-pointer"
                target="_blank"
                rel="noreferrer"
              >
                {' '}
                {t('account.loggedOut.discoverMoreHere')}
              </a>
            </p>
            <div className="flex gap-2 mt-4">
              <div className="flex gap-2 flex-col sm:flex-row flex-wrap items-center">
                {socialLogins.map((socialLogin) => (
                  <a
                    className={`block p-1 ${socialLogin.className} w-52 flex items-center rounded-md cursor-pointer`}
                    href={`${authUrl}/${socialLogin.path}?referer=${referer}`}
                    key={socialLogin.path}
                  >
                    <div className="w-10 h-10 bg-white rounded flex items-center justify-center p-1.5">
                      <img src={socialLogin.icon} alt={socialLogin.name} />
                    </div>{' '}
                    <div className="flex-1 text-center text-sm">
                      {t('account.loggedOut.loginWith', {
                        platform: socialLogin.name,
                      })}
                    </div>
                  </a>
                ))}
              </div>
            </div>
          </div>
        )}
        {userDetails.loggedIn && (
          <div>
            <p className="mt-3">
              {t('account.loggedIn.hi')} {userDetails.firstName} 👋
            </p>
            <p className="mt-2">{t('account.loggedIn.text')}</p>
            <p>
              <a
                href="https://account.teuteuf.fr"
                className="text-blue-500 dark:text-blue-400 hover:text-blue-400 cursor-pointer"
                target="_blank"
                rel="noreferrer"
              >
                {t('account.loggedIn.learnMore')}
              </a>
            </p>
            <div className="flex gap-2 mt-4">
              <button
                onClick={logout}
                className="bg-blue-600 hover:bg-blue-400 text-white font-bold py-2 px-4 rounded"
              >
                {t('account.loggedIn.signOut')}
              </button>
              <a href="https://account.teuteuf.fr">
                <button className="border border-slate-400 text-black dark:text-white font-bold py-2 px-4 rounded">
                  {t('account.loggedIn.manageAccount')}
                </button>
              </a>
            </div>
          </div>
        )}
      </div>
    </Container>
  );
};

export default AccountRoute;
