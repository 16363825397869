import { TeuteufGames, TeuteufGamesProps } from '@teuteuf-games/teuteuf-games';

import { BackButton } from '../components/BackButton';
import i18n from '../i18n';

export function TeuteufGamesRoute() {
  return (
    <div className="flex flex-col w-full items-start px-3 text-start mt-5">
      <BackButton />
      <TeuteufGames
        utmSource="flagle"
        langCode={i18n.resolvedLanguage as TeuteufGamesProps['langCode']}
      />
    </div>
  );
}
