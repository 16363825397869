export const en = {
  /*  
        iPhone Safari determines whether to autofill based on placeholder value -
        zero-width space prevents Safari's suggestion to autofill user's home country 
      */
  flag: 'Flag',
  shape: 'Shape',
  'Population & Currency': 'Population & Currency',
  'Capital flag': 'Capital',
  emblem: 'Emblem',
  neighbours: 'Neighbours',
  placeholder: 'Countr\u200By, territory...', // 'Country, territory...'
  guess: 'Guess',
  share: 'Share results',
  'unknown-country': 'Unknown country!',
  copy: 'Copied results to clipboard',
  'play-bonus': 'Play bonus round',
  'next-bonus': 'Next bonus round',
  'other-games': 'Our other games:',
  'submit-feedback': 'Submit Feedback',
  'remaining-guesses': 'You have {{attemptsLeft}} guesses remaining',
  main: {
    'make-a-guess': 'Make a guess to reveal the first tile',
    country: 'Country...',
    flag: 'Flag',
    'already-guessed': 'You have already guessed {{currentGuess}}',
  },
  quiz: {
    title: 'Population & Currency',
    'estimated-population':
      'What is the estimated population of {{countryName}}',
    'guess-currency': 'What is the currency used in {{countryName}}',
    population: 'Population',
    currency: 'Currency',
    micro: {
      'less-25': 'Less than 25',
      over: 'Over 2000',
    },
    small: {
      'first-option': '< 50 thousand',
      'second-option': '50 - 500 thousand',
      'third-option': '500k - 1 million',
      'fourth-option': '1+ million',
    },
    medium: {
      'first-option': '< 3 million',
      'second-option': '3 - 5 million',
      'third-option': '5 - 8 million',
      'fourth-option': '8+ million',
    },
    large: {
      'first-option': '< 15 million',
      'second-option': '15 - 25 million',
      'third-option': '25 - 35 million',
      'fourth-option': '35+ million',
    },
    massive: {
      'first-option': '< 60 million',
      'second-option': '60 - 80 million',
      'third-option': '80 - 100 million',
      'fourth-option': '100+ million',
    },
  },
  'shape-round': {
    title: `What's the shape of {{countryName}}`,
    sponsored: 'Sponsored by',
    like: 'Like this round',
    try: 'Try',
  },
  'capital-flag': {
    title: 'Pick the flag of the capital city of {{country}}: {{city}}',
  },
  'emblem-round': {
    title: 'Pick the National Emblem of {{countryName}}',
  },
  border: {
    title: 'Pick the flag of a country that neighbours {{countryName}}',
  },
  settings: {
    title: 'Settings',
    'distance-unit': 'Unit of distance',
    theme: 'Theme',
    language: 'Language',
    'difficulty-modifiers': 'Difficulty modifiers',
    grayscale: 'Grayscale flag',
  },
  stats: {
    title: 'Statistics',
    played: 'Played',
    win: 'Win %',
    currentStreak: 'Current Streak',
    maxStreak: 'Max Streak',
    guessDistribution: 'Guess distribution',
    donate: '🇺🇦 Donate to Ukraine ❤️',
  },
  'how-to-play': {
    title: 'How to play',
    guess: 'Guess the',
    'number-guesses': 'in 6 guesses.',
    'valid-country': 'Each guess must be a valid country or territory.',
    'after-guess':
      'After each guess, you will have the distance, the direction and the proximity from your guess to the target location.',
    examples: 'Examples',
    'your-guess': 'Your guess',
    is: 'is',
    away: `away from the target location, the target location is in the North-East direction and you have a only 32% of proximity because it's quite far away!`,
    'second-guess': 'Your second guess',
    'getting-closer':
      'is getting closer! {{distance}} away, South-East direction and 83%!',
    'next-guess': 'Next guess,',
    'right-guess': `it's the location to guess! Congrats`,
    'new-game': 'A new',
    available: 'will be available every day!',
    suggestions: 'Any question or suggestion?',
    check: 'Check the',
    faq: 'Flagle FAQ',
    made: 'Made by',
    ads: 'Ads by',
    privacy: 'Privacy Policy',
    feedback: 'Feedback',
    hiring: `We're Hiring`,
  },
  faq: {
    'when-new-game-available': {
      question: 'When is a new game available?',
      answer:
        'A new game is available every day at 0:00 am, local time of your device!',
    },
    'how-earn-bonus-round': {
      question: 'How do I earn passage to the next bonus round?',
      answer:
        'To get bonus round 1 (shape), you simply need to guess the country correct.',
    },
    'why-data-incorrect': {
      question: 'Why is your data incorrect or old?',
      answer:
        'We are trying to update our data as quickly and as accurately as possible. We know borders change, capital cities move, and maps are outdated. And more. Any mistake is completely unintentional and not a political statement of any kind. We appreciate the feedback and will issue fixes as we learn about them. As time goes on, there will be less and less to fix.',
    },
    'green-yellow-squares': {
      question: 'What do the green and yellow squares mean?',
      answer:
        'They correspond to the proximity percent:<br><br>• 🟩 = 20%<br>• 🟨 = 10%',
    },
    'proximity-percent': {
      question: 'What is the proximity percent?',
      answer:
        "It's another way to represent the distance.<br><br>If your guess is at the opposite side of the world, you'll get 0%.<br>If it's right on the target, you’ll get 100%!",
    },
    'how-distances-computed': {
      question: 'How are distances computed?',
      answer:
        'The distances displayed correspond to the distances between the selected and the target territory centers.<br><br>For instance, the computed distance between United States and Canada is around 2260km even if they have a common border.<br><br>Two reasons for this choice:<br><br>• it’s technically much easier to compute distance like this instead of using closest borders.<br><br>• you can deduce information about the size of the target country thanks to this information.',
    },
    'why-not-valid-answer': {
      question: 'Why a given country is not a valid answer?',
      answer:
        'I use the ISO 3166-1 standard as reference for the country code list.<br><br>For instance, Scotland is not a valid answer, because, according to this standard, this country is part of the United Kingdom.',
    },
    'how-countries-picked': {
      question:
        'How country and territory are picked? What will happen when all the countries have been picked?',
      answer:
        "Everyday, a country or territory is picked randomly!<br><br>So, it will continue to pick a random one everyday, forever!<br><br>Two rules have been added:<br><br>• it doesn’t pick a country or territory of less than 5,000 km2 when one has already been picked less than 7 days ago.<br>• it doesn’t pick a country that has already been picked less than 100 days ago.<br>• it doesn't pick a country from the same continent as yesterday, (only applies for Africa and Asia).",
    },
    'why-ads': {
      question: 'Why do I see ads on my device?',
      answer:
        'We use ads to pay for developing costs for new features, as well as hosting and other expenses. Over time @teuteuf has received many suggestions and requests that he could not fulfill. We want to bring more features, and more fun gameplay to YOU. Our Plan is to bring you an official mobile app as well as countless bonus featurs to keep Flagle the most fun geo quiz-game on the internet!',
    },
  },
  account: {
    title: 'Account',
    loggedIn: {
      hi: 'Hi',
      text: 'We now store your stats after each game. This not only safeguards your progress but also enables seamless gameplay across multiple devices.',
      learnMore: 'Learn more',
      signOut: 'Sign out',
      manageAccount: 'Manage account',
    },
    loggedOut: {
      text: 'Sign up for a Teuteuf Games account or log in to start saving your gaming statistics and playing across multiple devices. Want to know all the benefits?',
      discoverMoreHere: 'Discover more here',
      signUp: 'Sign up',
      login: 'Login',
      loginWith: 'Login with {{platform}}',
    },
  },
};
