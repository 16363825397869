import { memo } from 'react';
import Faq from 'react-faq-component';
import { useTranslation } from 'react-i18next';
import { JsonLd } from 'react-schemaorg';
import { FAQPage } from 'schema-dts';

const styles = {
  bgColor: 'transparent',
  titleTextColor: 'currentColor',
  rowTitleColor: 'currentColor',
  rowContentColor: 'currentColor',
  arrowColor: 'currentColor',
  titleTextSize: '24px',
  rowContentTextSize: '16px',
  rowContentPaddingTop: '10px',
  rowContentPaddingBottom: '10px',
};

export interface FaqItemProp {
  content: string;
  title: string;
}

export const FaqSection = () => {
  const { t } = useTranslation();

  // Geração do esquema JSON-LD
  const faqSchema = (rows: FaqItemProp[]) => {
    const faqItems = rows.map((row) => ({
      '@type': 'Question' as const, // Adiciona o tipo literal correto
      name: row.title,
      acceptedAnswer: {
        '@type': 'Answer' as const, // Adiciona o tipo literal correto
        text: row.content,
      },
    }));

    return (
      <JsonLd<FAQPage>
        item={{
          '@context': 'https://schema.org',
          '@type': 'FAQPage',
          mainEntity: faqItems,
        }}
      />
    );
  };

  // Dados do FAQ
  const faqData = {
    title: t('faq.title', 'F.A.Q.'),
    rows: [
      {
        title: t('faq.when-new-game-available.question'),
        content: t('faq.when-new-game-available.answer'),
      },
      {
        title: t('faq.how-earn-bonus-round.question'),
        content: t('faq.how-earn-bonus-round.answer'),
      },
      {
        title: t('faq.why-data-incorrect.question'),
        content: t('faq.why-data-incorrect.answer'),
      },
      {
        title: t('faq.green-yellow-squares.question'),
        content: t('faq.green-yellow-squares.answer'),
      },
      {
        title: t('faq.proximity-percent.question'),
        content: t('faq.proximity-percent.answer'),
      },
      {
        title: t('faq.how-distances-computed.question'),
        content: t('faq.how-distances-computed.answer'),
      },
      {
        title: t('faq.why-not-valid-answer.question'),
        content: t('faq.why-not-valid-answer.answer'),
      },
      {
        title: t('faq.how-countries-picked.question'),
        content: t('faq.how-countries-picked.answer'),
      },
      {
        title: t('faq.why-ads.question'),
        content: t('faq.why-ads.answer'),
      },
    ],
  };

  return (
    <>
      <div className="p-2 pt-8 w-full flex faq-content">
        {faqSchema(faqData.rows)}
        <Faq data={faqData} styles={styles} />
      </div>
    </>
  );
};

export default memo(FaqSection);
