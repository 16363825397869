import axios, { AxiosResponse } from 'axios';

let SERVER_URL = '';

if (typeof window !== 'undefined') {
  if (
    window.location.hostname === 'localhost' ||
    window.location.hostname === '127.0.0.1'
  ) {
    SERVER_URL = 'http://localhost:3000';
  } else if (window.location.hostname.includes('staging')) {
    SERVER_URL = 'https://staging-auth.teuteuf.fr';
  } else {
    SERVER_URL = 'https://auth.teuteuf.fr';
  }
}

export const refreshTokens = async (): Promise<AxiosResponse | null> => {
  let token = localStorage.getItem('token') ?? undefined;
  let refreshToken = localStorage.getItem('refreshToken') ?? undefined;
  if (!token || !refreshToken) {
    return null;
  }
  const response = await axios.post(`${SERVER_URL}/auth/refresh`, {
    token,
    refreshToken,
  });
  if (response?.data) {
    token = response.data.token;
    refreshToken = response.data.refreshToken;
    localStorage.setItem('token', token!);
    localStorage.setItem('refreshToken', refreshToken!);
    localStorage.setItem('refreshDate', new Date().toUTCString());
  }
  return response;
};

export const getUserDetails = async (): Promise<AxiosResponse | null> => {
  const token = localStorage.getItem('token') ?? undefined;
  if (!token) {
    return null;
  }
  const response = await axios.get(`${SERVER_URL}/api/getuser`, {
    headers: { Authorization: 'Bearer ' + token },
  });
  return response;
};

export const getData = async <T>(key: string): Promise<T | null> => {
  const token = localStorage.getItem('token') ?? undefined;
  if (!token) {
    return null;
  }
  const response = await axios.post(
    `${SERVER_URL}/api/getdata`,
    {
      gameId: 'flagle',
      key,
    },
    {
      headers: { Authorization: 'Bearer ' + token },
    },
  );
  if (
    response.status < 200 ||
    response.status >= 300 ||
    !response.data?.value
  ) {
    return null;
  }
  return response.data.value;
};

export const setData = async <T>(key: string, value: T): Promise<boolean> => {
  const token = localStorage.getItem('token') ?? undefined;
  if (!token) {
    return false;
  }
  const response = await axios.post(
    `${SERVER_URL}/api/setdata`,
    {
      gameId: 'flagle',
      key,
      value,
    },
    {
      headers: { Authorization: 'Bearer ' + token },
    },
  );
  if (
    response.status < 200 ||
    response.status >= 300 ||
    !response.data?.value
  ) {
    return false;
  }
  return true;
};
