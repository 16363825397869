import { useTranslation } from 'react-i18next';
import { styled } from 'styled-components';

import { BackButton } from '../components/BackButton';
import EmojiRender from '../components/EmojiRender';
import { GuessList } from '../components/GuessList';
import { useDistanceInUserUnit } from '../hooks/useDistanceInUserUnit';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  gap: 12px;
  padding-left: 12px;
  padding-right: 12px;
  margin-top: 20px;
`;

export const RulesRoute = () => {
  const { formatDistance } = useDistanceInUserUnit();
  const { t } = useTranslation();

  return (
    <Container>
      <BackButton />

      <div className="space-y-3 text-justify border-b-2 border-gray-200 pb-3 mb-3">
        <h3 className="text-xl font-bold mb-3">{t('how-to-play.title')}</h3>
        <div className="space-y-3 text-justify border-b-2 border-gray-200 pb-3 mb-3">
          <div>
            {t('how-to-play.guess')} Flag
            <span style={{ color: '#1a76d2' }}>le</span>{' '}
            {t('how-to-play.number-guesses')}
          </div>
          <div>{t('how-to-play.valid-country')}</div>
          <div>{t('how-to-play.after-guess')}</div>
        </div>
        <div className="space-y-3 text-justify border-b-2 border-gray-200 pb-3 mb-3">
          <div className="font-bold">{t('how-to-play.examples')}</div>
          <div>
            <GuessList
              guesses={[
                {
                  name: 'Chile',
                  distance: 13_557_000,
                  direction: 'NE',
                  tile: 1,
                },
              ]}
            />
            <div className="my-2">
              {t('how-to-play.your-guess')}{' '}
              <span className="uppercase font-bold">Chile</span>{' '}
              {t('how-to-play.is')} {formatDistance(13557000)}{' '}
              {t('how-to-play.away')}
            </div>
          </div>
          <div>
            <GuessList
              guesses={[
                {
                  name: 'Finland',
                  direction: 'SE',
                  distance: 3_206_000,
                  tile: 4,
                },
              ]}
            />
            <div className="my-2">
              {t('how-to-play.second-guess')}{' '}
              <span className="uppercase font-bold">Finland</span>{' '}
              {t('how-to-play.getting-closer', {
                distance: formatDistance(3206000),
              })}
            </div>
          </div>
          <div>
            <GuessList
              guesses={[
                {
                  name: 'Lebanon',
                  direction: 'N',
                  distance: 0,
                  tile: 6,
                },
              ]}
            />
            <div className="my-2">
              {t('how-to-play.next-guess')}{' '}
              <span className="uppercase font-bold">Lebanon</span>,{' '}
              {t('how-to-play.right-guess')}{' '}
              <EmojiRender text="🎉" options={{ className: 'inline-block' }} />
            </div>
          </div>
        </div>
        <div className="space-y-3 text-justify border-b-2 border-gray-200 pb-3 mb-3 font-bold">
          {t('how-to-play.new-game')} Flag
          <span style={{ color: '#1a76d2' }}>le</span>{' '}
          {t('how-to-play.available')}
        </div>
        <div className="space-y-3 text-justify pb-2">
          <div>
            {t('how-to-play.made')}{' '}
            <a
              className="underline"
              href="https://twitter.com/teuteuf"
              target="_blank"
              rel="noopener noreferrer"
            >
              @teuteuf
            </a>{' '}
          </div>
        </div>
        <div className="space-y-3 text-justify pb-3 border-b-2 border-gray-200 mb-3">
          <div>
            &nbsp;{t('how-to-play.ads')}
            <a
              className="underline pl-1"
              href={'https://Snigel.com'}
              target="_blank"
              rel="noreferrer"
            >
              Snigel
            </a>
          </div>
        </div>
        <div className="copy flex justify-center items-center">
          <a
            href="https://teuteuf.fr/privacy.html"
            className="underline pl-1"
            target="_blank"
            rel="noreferrer"
          >
            {t('how-to-play.privacy')}
          </a>
          &nbsp;&nbsp;-&nbsp;&nbsp;
          <a
            className="underline"
            href="https://teuteuf.notion.site/17eeff4e9f3880e2a6aeef075e74939b?pvs=105"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('how-to-play.feedback')}
          </a>
          &nbsp;&nbsp;-&nbsp;&nbsp;
          <a
            className="underline font-bold"
            href="https://teuteuf.fr/jobs.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('how-to-play.hiring')}
          </a>
        </div>
      </div>
    </Container>
  );
};

export default RulesRoute;
