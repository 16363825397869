import { isAxiosError } from 'axios';
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useLocalStorage } from 'usehooks-ts';

import { getDayString } from '../hooks/useDailySeed';
import { GuessHistory } from '../hooks/useGuessHistory';
import { initialUserDetailsState, UserDetails } from '../types/UserDetails';
import {
  getData,
  getUserDetails,
  refreshTokens,
  setData,
} from '../utils/account';
import { checkPhotoValidity, isSameUtcDay } from '../utils/helpers';
import { calculateStats, defaultStats, Stats } from '../utils/stats';

/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
const UserContext = createContext({
  isLoaded: false,
  referer: '',
  userDetails: initialUserDetailsState,
  logout: () => {},
  setUserDetails: (_userDetails: UserDetails) => {},
  statsUpdated: false,
  setStatsUpdated: (_statsUpdated: boolean) => {},
  playedTodayOnOtherDevice: false,
  setPlayedTodayOnOtherDevice: (_playedTodayOnOtherDevice: boolean) => {},
});

export const useUser = () => {
  return useContext(UserContext);
};

export const UserProvider = ({ children }: { children: ReactNode }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [userDetails, setUserDetails] = useState<UserDetails>(
    initialUserDetailsState,
  );
  const [statsUpdated, setStatsUpdated] = useState(false);
  const [playedTodayOnOtherDevice, setPlayedTodayOnOtherDevice] =
    useState(false);
  const [, setGuesses] = useLocalStorage<GuessHistory>('guesses', {});

  // Generate stats if it's not been done yet
  if (!localStorage.getItem('stats')) {
    const guessesRaw = localStorage.getItem('guesses');
    if (guessesRaw) {
      try {
        const guesses = JSON.parse(guessesRaw) as GuessHistory;
        localStorage.setItem('stats', JSON.stringify(calculateStats(guesses)));
      } catch (e) {
        console.error('Error parsing guesses', e);
      }
    }
  }

  let referer = '';

  // Check if the project is running locally
  if (typeof window !== 'undefined') {
    if (
      window.location.hostname === 'localhost' ||
      window.location.hostname === '127.0.0.1'
    ) {
      referer = 'localhost-3000';
    } else if (window.location.hostname.includes('staging')) {
      referer = 'flagle-staging';
    } else {
      referer = 'flagle';
    }
  }

  const logout = () => {
    setUserDetails(initialUserDetailsState);
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('teuteuf-user');
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const syncData = async (initalSync: boolean = false) => {
      if (initalSync) {
        let [flagleRoundsCompleted, guesses, stats] = await Promise.all([
          getData<Record<string, string[]> | null>('flagle-rounds-completed'),
          getData<GuessHistory | null>('guesses'),
          getData<Stats | null>('stats'),
        ]);
        flagleRoundsCompleted ||= {};
        guesses ||= {};
        stats ||= defaultStats;

        let shouldUploadRounds = false;
        let shouldUploadGuesses = false;
        let shouldUploadStats = false;

        const flagleRoundsCompletedLocal = localStorage.getItem(
          'flagle-rounds-completed',
        );
        const guessesLocal = localStorage.getItem('guesses');
        const statsLocal = localStorage.getItem('stats');

        // Merge our stats in
        if (flagleRoundsCompletedLocal) {
          try {
            const flagleRoundsCompletedLocalParsed = JSON.parse(
              flagleRoundsCompletedLocal,
            );
            // Only merge in the local ones if there are new keys
            for (const key in flagleRoundsCompletedLocalParsed) {
              if (!flagleRoundsCompleted[key]) {
                flagleRoundsCompleted[key] =
                  flagleRoundsCompletedLocalParsed[key];
                shouldUploadRounds = true;
              }
            }
          } catch (e) {
            console.error('Error parsing flagle-rounds-completed', e);
          }
        }
        if (guessesLocal) {
          try {
            const guessesLocalParsed = JSON.parse(guessesLocal);
            // Only merge in the local ones if there are new keys
            const dayString = getDayString();
            if (dayString in guessesLocalParsed && !(dayString in guesses)) {
              // Only upload if today's guess is here, but not on server
              shouldUploadGuesses = true;
            }
            for (const key in guessesLocalParsed) {
              if (!guesses[key]) {
                guesses[key] = guessesLocalParsed[key];
              }
            }
          } catch (e) {
            console.error('Error parsing guesses', e);
          }
        }
        if (statsLocal) {
          try {
            const statsLocalParsed = JSON.parse(statsLocal);
            if (statsLocalParsed.played > stats.played) {
              stats = { ...statsLocalParsed };
              shouldUploadStats = true;
            }
          } catch (e) {
            console.error('Error parsing stats', e);
          }
        }

        localStorage.setItem(
          'flagle-rounds-completed',
          JSON.stringify(flagleRoundsCompleted),
        );
        setGuesses(guesses);
        localStorage.setItem('stats', JSON.stringify(stats));
        const promises = [];
        if (shouldUploadRounds) {
          promises.push(
            setData('flagle-rounds-completed', flagleRoundsCompleted),
          );
        }
        if (shouldUploadStats) {
          promises.push(setData('stats', stats));
        }
        if (shouldUploadGuesses) {
          const dayString = getDayString();
          if (dayString in guesses) {
            setData('guesses', { [dayString]: guesses[dayString] });
          }
        }
        await Promise.all(promises);
      }
    };

    async function loadUserAndRefreshTokens() {
      const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
      const encodedRefreshTime = localStorage.getItem('refreshDate');
      const refreshTime = encodedRefreshTime
        ? decodeURIComponent(encodedRefreshTime)
        : null;

      // Load user from local storage - This could be stale data, but will update after refresh.
      const userFromLocalStorage = localStorage.getItem('teuteuf-user');

      try {
        // Read hash, and try and log the user in
        const hash = window.location.hash;
        if (hash.startsWith('#userData=')) {
          const rawUserData = decodeURIComponent(
            hash.substring('#userData='.length),
          );
          const result = JSON.parse(rawUserData);
          localStorage.setItem('isLoggedIn', 'true');
          localStorage.setItem('token', result.token);
          localStorage.setItem('refreshToken', result.refreshToken);
          localStorage.setItem('refreshDate', new Date().toUTCString());
          // Once logged in, redirect to homepage
          window.location.href = '/';
        }
      } catch (e) {
        console.error('Error logging in', e);
      }

      if (isLoggedIn) {
        if (userFromLocalStorage) {
          const parsedUser: UserDetails = JSON.parse(userFromLocalStorage);
          setUserDetails(parsedUser);
        }
        try {
          if (refreshTime && !isSameUtcDay(refreshTime)) {
            console.log('Refreshing tokens...');
            await refreshTokens();
          } else {
            console.log('Not refreshing tokens');
          }

          const response = await getUserDetails();
          if (response) {
            const newUserDetails: UserDetails = {
              loggedIn: true,
              firstName: response.data.firstName,
              photoURL: response.data.photoURL,
              isPhotoValid: false, // default to false until we verify the photo
              marketingOptIn: String(response.data.marketingOptIn),
              subscription: response.data.subscription,
              premiumGames: response.data.premiumGames ?? [],
            };
            setUserDetails(newUserDetails);
            localStorage.setItem(
              'teuteuf-user',
              JSON.stringify(newUserDetails),
            );

            checkPhotoValidity(newUserDetails.photoURL).then((isValid) => {
              setUserDetails((prevState) => {
                const updatedUserDetails = {
                  ...prevState,
                  isPhotoValid: isValid,
                };
                localStorage.setItem(
                  'teuteuf-user',
                  JSON.stringify(updatedUserDetails),
                );
                return updatedUserDetails;
              });
            });

            syncData(true);
          } else {
            // Not logged in!
            setUserDetails(initialUserDetailsState);
          }
        } catch (e) {
          // check if network error
          if (isAxiosError(e) && e.response?.status) {
            setUserDetails(initialUserDetailsState);
            localStorage.removeItem('datePlayedOnOtherDevice');
            localStorage.setItem(
              'teuteuf-user',
              JSON.stringify(initialUserDetailsState),
            );
          }
          console.log(e);
        } finally {
          setIsLoaded(true);
        }
      } else {
        setIsLoaded(true);
      }
    }
    loadUserAndRefreshTokens();
    const datePlayedOnOtherDevice = localStorage.getItem(
      'datePlayedOnOtherDevice',
    );
    if (datePlayedOnOtherDevice && datePlayedOnOtherDevice === getDayString()) {
      console.log('setting played today on other device');
      setPlayedTodayOnOtherDevice(true);
    }
  }, []);

  return (
    <UserContext.Provider
      value={{
        logout,
        referer,
        isLoaded,
        userDetails,
        setUserDetails,
        statsUpdated,
        setStatsUpdated,
        playedTodayOnOtherDevice,
        setPlayedTodayOnOtherDevice,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
