/* eslint-disable no-prototype-builtins */
import * as translations from '../i18n/locales';
// To parse this data:
//
//   import { Convert, CommonPhrases } from "./file";
//
//   const commonPhrases = Convert.toCommonPhrases(json);
//
// These functions will throw an error if the JSON doesn't
// match the expected interface, even if the JSON is valid.

const transMe = (lang: string, key: string): string => {
  const lkeys = Object.keys(translations);
  const lvalue = Object.values(translations);
  const lki = lkeys.indexOf(lang);
  if (lki !== -1) {
    const pdata = lvalue[lki];
    const pkeys = Object.keys(pdata);
    const pvals = Object.values(pdata);
    for (const pki in pkeys) {
      const pkey = pkeys[pki];

      if (pkey.toLowerCase() === key.toLowerCase()) {
        return pvals[pki] as string;
      }
    }
  }
  return '';
};

export const languages = Object.keys(translations);

export default transMe;
