export function isSameUtcDay(refreshTimeString: string): boolean {
  // Parse the refreshTime as a Date object
  const refreshDate = new Date(refreshTimeString);

  // Get the current date in UTC
  const currentDate = new Date();

  // Compare the year, month, and day
  return (
    refreshDate.getUTCFullYear() === currentDate.getUTCFullYear() &&
    refreshDate.getUTCMonth() === currentDate.getUTCMonth() &&
    refreshDate.getUTCDate() === currentDate.getUTCDate()
  );
}

export const checkPhotoValidity = (photoURL: string): Promise<boolean> => {
  return new Promise((resolve) => {
    const image = new Image();
    image.src = photoURL;
    image.onload = () => resolve(true);
    image.onerror = () => resolve(false);
  });
};
