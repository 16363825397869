import IosShareRoundedIcon from '@mui/icons-material/IosShareRounded';
import dayjs from 'dayjs';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import {
  browserName,
  BrowserView,
  MobileView,
  osName,
} from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { MAX_ATTEMPTS, TILE_COUNT } from '../constants';
import { useDailySeed } from '../hooks/useDailySeed';
import { Guess, useGuessHistory } from '../hooks/useGuessHistory';
import { useRoundEmojis } from '../hooks/useRoundEmojis';

const FIRST_DAY_OF_FLAGLE = DateTime.fromFormat(
  'February 21 2022',
  'LLLL dd yyyy',
);

const generateShareSquares = ({
  score,
  guesses,
}: {
  score: number | 'DNF';
  guesses: Guess[];
}) => {
  if (score === 'DNF') {
    return '🟥🟥🟥\n🟥🟥🟥\n';
  }

  const squares = Array(TILE_COUNT).fill('🟩');
  for (let i = 0; i < guesses.length - 1; i++) {
    squares[guesses[i].tile] = '🟥';
  }

  for (let i = 0; i < TILE_COUNT; i++) {
    if ((i + 1) % 3 === 0) {
      squares[i] += '\n';
    }
  }
  return squares.join('');
};

export function ShareButton() {
  const dailySeed = useDailySeed();
  const [guessHistory] = useGuessHistory();
  const textEmojis = useRoundEmojis();
  const { t } = useTranslation();

  const shareText = useMemo(() => {
    const dailyGuesses = guessHistory[dailySeed] || [];
    const score = dailyGuesses.some(({ distance }) => distance === 0)
      ? dailyGuesses.length
      : 'DNF';

    const trueDayString = dailySeed.slice(0, -2);
    const currentDate = DateTime.fromFormat(trueDayString, 'yyyy-MM-dd');
    const dateDiff = currentDate.diff(FIRST_DAY_OF_FLAGLE, 'days').toObject();
    const squareString = generateShareSquares({
      score,
      guesses: dailyGuesses,
    });
    const date = dayjs(trueDayString).format('DD.MM.YYYY');

    return `#Flagle #${dateDiff.days} (${date}) ${
      score === 'DNF' ? 'X' : dailyGuesses.length
    }/${MAX_ATTEMPTS}\n${squareString}${textEmojis}\nhttps://www.flagle.io`;
  }, [guessHistory, dailySeed, textEmojis]);

  const shareResult = () => {
    navigator.share({
      title: 'Flagle',
      text: shareText,
    });
  };

  return (
    <>
      <BrowserView>
        <CopyToClipboard
          text={shareText}
          onCopy={() => toast.success(t('copy'), { autoClose: 2000 })}
          options={{
            format: 'text/plain',
          }}
        >
          <button className="flex w-full h-10 border rounded-lg px-2 py-1 text-center font-semibold whitespace-nowrap hover:bg-gray-200 hover:dark:bg-gray-500 items-center gap-2 overflow-ellipsis">
            <IosShareRoundedIcon fontSize="small" />
            {t('share')}
          </button>
        </CopyToClipboard>
      </BrowserView>
      <MobileView>
        {/* Firefox Android does not support navigator.share with text */}
        {!navigator.share ||
        (browserName === 'Firefox' && osName === 'Android') ? (
          <CopyToClipboard
            text={shareText}
            onCopy={() => toast.success(t('copy'), { autoClose: 2000 })}
            options={{
              format: 'text/plain',
            }}
          >
            <button className="flex h-10 w-10 border rounded-lg px-2 py-1 text-center font-semibold whitespace-nowrap hover:bg-gray-200 hover:dark:bg-gray-500 items-center justify-center">
              <IosShareRoundedIcon fontSize="small" />
            </button>
          </CopyToClipboard>
        ) : (
          <button
            onClick={shareResult}
            className="flex h-10 w-10 border rounded-lg px-2 py-1 text-center font-semibold whitespace-nowrap hover:bg-gray-200 hover:dark:bg-gray-500 items-center justify-center"
          >
            <IosShareRoundedIcon fontSize="small" />
          </button>
        )}
      </MobileView>
    </>
  );
}
