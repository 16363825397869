import { GuessRows } from '@teuteuf-games/guess-rows';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useDistanceInUserUnit } from '../hooks/useDistanceInUserUnit';
import { Guess } from '../hooks/useGuessHistory';
import {
  computeProximityPercent,
  generateSquareCharacters,
  getDirectionEmoji,
} from '../utils/geography';

export const GuessList: React.FC<{
  guesses: Guess[];
  maxTryCount?: number;
  gameEnded?: boolean;
  inputRef?: React.RefObject<HTMLInputElement>;
}> = ({ guesses, maxTryCount, gameEnded, inputRef }) => {
  const { formatDistance } = useDistanceInUserUnit();
  const { t } = useTranslation();

  const componentGuesses = useMemo(() => {
    return guesses.map((guess: Guess) => {
      return {
        text: guess.name,
        distance: formatDistance(guess.distance),
        emoji: getDirectionEmoji(guess),
        percent: computeProximityPercent(guess.distance),
        squares: generateSquareCharacters(guess.distance),
      };
    });
  }, [formatDistance, guesses]);

  return (
    <GuessRows
      maxTryCount={maxTryCount ?? 1}
      gameEnded={gameEnded ?? true}
      inputRef={inputRef}
      nextGuessLabel={t('guess')}
      guesses={componentGuesses}
      getGuessText={(g) => g.text}
      getGuessDistance={(g) => g.distance}
      getGuessDirectionEmoji={(g) => g.emoji}
      getGuessPercentage={(g) => g.percent}
      getGuessAnimationSquares={(g) => g.squares}
    />
  );
};
