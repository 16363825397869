import { Translations } from '../translations.types';

export const es: Translations = {
  flag: 'Bandera',
  shape: 'Forma',
  'Population & Currency': 'Población y Moneda',
  'Capital flag': 'Capital',
  emblem: 'Emblema',
  neighbours: 'Vecinos',
  placeholder: 'País\u200by, territorio...',
  guess: 'Adivinar',
  share: 'Compartir resultados',
  'unknown-country': '¡País desconocido!',
  copy: 'Resultados copiados al portapapeles',
  'play-bonus': 'Jugar ronda de bonificación',
  'next-bonus': 'Siguiente ronda de bonificación',
  'other-games': 'Nuestros otros juegos:',
  'submit-feedback': 'Enviar comentarios',
  'remaining-guesses': 'Te quedan {{attemptsLeft}} intentos',
  main: {
    'make-a-guess': 'Haz una suposición para revelar la primera ficha',
    country: 'País...',
    flag: 'Bandera',
    'already-guessed': 'Ya has adivinado {{currentGuess}}',
  },
  quiz: {
    title: 'Población y Moneda',
    'estimated-population':
      '¿Cuál es la población estimada de {{countryName}}?',
    'guess-currency': '¿Cuál es la moneda utilizada en {{countryName}}?',
    population: 'Población',
    currency: 'Moneda',
    micro: {
      'less-25': 'Menos de 25',
      over: 'Más de 2000',
    },
    small: {
      'first-option': '< 50 mil',
      'second-option': '50 - 500 mil',
      'third-option': '500k - 1 millón',
      'fourth-option': '1+ millón',
    },
    medium: {
      'first-option': '< 3 millones',
      'second-option': '3 - 5 millones',
      'third-option': '5 - 8 millones',
      'fourth-option': '8+ millones',
    },
    large: {
      'first-option': '< 15 millones',
      'second-option': '15 - 25 millones',
      'third-option': '25 - 35 millones',
      'fourth-option': '35+ millones',
    },
    massive: {
      'first-option': '< 60 millones',
      'second-option': '60 - 80 millones',
      'third-option': '80 - 100 millones',
      'fourth-option': '100+ millones',
    },
  },
  'shape-round': {
    title: '¿Cuál es la forma de {{countryName}}',
    sponsored: 'Patrocinado por',
    like: 'Te gusta esta ronda',
    try: 'Intenta',
  },
  'capital-flag': {
    title: 'Elige la bandera de la capital de {{country}}: {{city}}',
  },
  'emblem-round': {
    title: 'Elige el emblema nacional de {{countryName}}',
  },
  border: {
    title: 'Elige la bandera de un país vecino de {{countryName}}',
  },
  settings: {
    title: 'Configuración',
    'distance-unit': 'Unidad de distancia',
    theme: 'Tema',
    language: 'Idioma',
    'difficulty-modifiers': 'Modificadores de dificultad',
    grayscale: 'Bandera en escala de grises',
  },
  stats: {
    title: 'Estadísticas',
    played: 'Jugado',
    win: 'Porcentaje de victorias',
    currentStreak: 'Racha actual',
    maxStreak: 'Racha máxima',
    guessDistribution: 'Distribución de intentos',
    donate: '🇺🇦 Dona a Ucrania ❤️',
  },
  'how-to-play': {
    title: 'Cómo jugar',
    guess: 'Adivina el',
    'number-guesses': 'en 6 intentos.',
    'valid-country': 'Cada intento debe ser un país o territorio válido.',
    'after-guess':
      'Después de cada intento, obtendrás la distancia, la dirección y la proximidad entre tu elección y la ubicación objetivo.',
    examples: 'Ejemplos',
    'your-guess': 'Tu intento',
    is: 'está',
    away: 'lejos de la ubicación objetivo, está al noreste y solo tienes un 32% de proximidad porque está bastante lejos.',
    'second-guess': 'Tu segundo intento',
    'getting-closer':
      '¡está más cerca! A {{distance}}, dirección sureste y 83%.',
    'next-guess': 'Siguiente intento,',
    'right-guess': '¡es la ubicación a adivinar! Felicidades',
    'new-game': 'Un nuevo',
    available: 'estará disponible cada día.',
    suggestions: '¿Alguna pregunta o sugerencia?',
    check: 'Consulta el',
    faq: 'FAQ de Flagle',
    made: 'Hecho por',
    ads: 'Anuncios por',
    privacy: 'Política de privacidad',
    feedback: 'Comentarios',
    hiring: 'Estamos contratando',
  },
  faq: {
    'when-new-game-available': {
      question: '¿Cuándo estará disponible un nuevo juego?',
      answer:
        '¡Un nuevo juego está disponible todos los días a las 0:00 a.m., hora local de tu dispositivo!',
    },
    'how-earn-bonus-round': {
      question:
        '¿Cómo puedo ganar el pase a la siguiente ronda de bonificación?',
      answer:
        'Para obtener la primera ronda de bonificación (forma), solo necesitas adivinar correctamente el país.',
    },
    'why-data-incorrect': {
      question: '¿Por qué tus datos son incorrectos o están desactualizados?',
      answer:
        'Estamos tratando de actualizar nuestros datos lo más rápido y preciso posible. Sabemos que las fronteras cambian, las capitales se mueven y los mapas quedan obsoletos. Cualquier error es completamente involuntario y no representa una declaración política. Agradecemos los comentarios y corregiremos los errores tan pronto como los detectemos. Con el tiempo, habrá cada vez menos errores.',
    },
    'green-yellow-squares': {
      question: '¿Qué significan los cuadrados verdes y amarillos?',
      answer:
        'Corresponden al porcentaje de proximidad:<br><br>• 🟩 = 20%<br>• 🟨 = 10%',
    },
    'proximity-percent': {
      question: '¿Qué es el porcentaje de proximidad?',
      answer:
        'Es otra forma de representar la distancia.<br><br>Si tu respuesta está en el lado opuesto del mundo, obtendrás 0%.<br>Si está justo en el objetivo, obtendrás 100%.',
    },
    'how-distances-computed': {
      question: '¿Cómo se calculan las distancias?',
      answer:
        'Las distancias mostradas corresponden a las distancias entre los centros del territorio seleccionado y del objetivo.<br><br>Por ejemplo, la distancia calculada entre Estados Unidos y Canadá es de aproximadamente 2260 km, incluso si comparten una frontera común.<br><br>Dos razones para esta elección:<br><br>• Es técnicamente mucho más fácil calcular la distancia de esta manera en lugar de usar las fronteras más cercanas.<br><br>• Puedes deducir información sobre el tamaño del país objetivo gracias a esta información.',
    },
    'why-not-valid-answer': {
      question: '¿Por qué un país dado no es una respuesta válida?',
      answer:
        'Utilizo el estándar ISO 3166-1 como referencia para la lista de códigos de países.<br><br>Por ejemplo, Escocia no es una respuesta válida porque, según este estándar, este país forma parte del Reino Unido.',
    },
    'how-countries-picked': {
      question:
        '¿Cómo se eligen los países y territorios? ¿Qué sucederá cuando se hayan elegido todos los países?',
      answer:
        '¡Cada día, se elige un país o territorio de forma aleatoria!<br><br>Así que continuará eligiendo uno al azar todos los días, para siempre.<br><br>Se han añadido dos reglas:<br><br>• No elige un país o territorio de menos de 5,000 km² si ya se ha elegido uno hace menos de 7 días.<br>• No elige un país que ya se haya elegido hace menos de 100 días.<br>• No elige un país del mismo continente que el de ayer (solo aplica para África y Asia).',
    },
    'why-ads': {
      question: '¿Por qué veo anuncios en mi dispositivo?',
      answer:
        'Usamos anuncios para cubrir los costos de desarrollo de nuevas funciones, así como el alojamiento y otros gastos. Con el tiempo, @teuteuf ha recibido muchas sugerencias y solicitudes que no pudo cumplir. Queremos ofrecerte más funciones y un juego más divertido. Nuestro plan es traerte una aplicación móvil oficial, así como innumerables funciones adicionales para mantener a Flagle como el juego de trivia geográfica más divertido en Internet.',
    },
  },
  account: {
    title: 'Cuenta',
    loggedIn: {
      hi: 'Hola',
      text: 'Ahora almacenamos tus estadísticas después de cada juego. Esto no solo protege su progreso, sino que también permite jugar sin problemas en múltiples dispositivos.',
      learnMore: 'Aprende más',
      signOut: 'Desconectar',
      manageAccount: 'Administrar cuenta',
    },
    loggedOut: {
      text: 'Regístrese para obtener una cuenta de Teuteuf Games o inicie sesión para comenzar a guardar sus estadísticas de juego y jugar en varios dispositivos. ¿Quieres conocer todos los beneficios?',
      discoverMoreHere: 'Descubre más aquí',
      signUp: 'Inscribirse',
      login: 'Acceso',
      loginWith: 'Iniciar sesión con {{platform}}',
    },
  },
};
