import { Translations } from '../translations.types';

export const pl: Translations = {
  flag: 'Flaga',
  shape: 'Kształt',
  'Population & Currency': 'Ludność i Waluta',
  'Capital flag': 'Stolica',
  emblem: 'Godło',
  neighbours: 'Sąsiedzi',
  placeholder: 'Kraj\u200b, terytorium...',
  guess: 'Zgadnij',
  share: 'Udostępnij wyniki',
  'unknown-country': 'Nieznany kraj!',
  copy: 'Wyniki skopiowane do schowka',
  'play-bonus': 'Zagraj w rundę bonusową',
  'next-bonus': 'Następna runda bonusowa',
  'other-games': 'Nasze inne gry:',
  'submit-feedback': 'Prześlij opinię',
  'remaining-guesses': 'Pozostało Ci {{attemptsLeft}} prób',
  main: {
    'make-a-guess': 'Zgadnij, aby odsłonić pierwszy kafelek',
    country: 'Kraj...',
    flag: 'Flaga',
    'already-guessed': 'Już zgadłeś {{currentGuess}}',
  },
  quiz: {
    title: 'Ludność i Waluta',
    'estimated-population': 'Jaka jest szacowana ludność {{countryName}}?',
    'guess-currency': 'Jaką walutę używa się w {{countryName}}?',
    population: 'Ludność',
    currency: 'Waluta',
    micro: {
      'less-25': 'Mniej niż 25',
      over: 'Ponad 2000',
    },
    small: {
      'first-option': '< 50 tysięcy',
      'second-option': '50 - 500 tysięcy',
      'third-option': '500 tys. - 1 milion',
      'fourth-option': '1+ milion',
    },
    medium: {
      'first-option': '< 3 miliony',
      'second-option': '3 - 5 milionów',
      'third-option': '5 - 8 milionów',
      'fourth-option': '8+ milionów',
    },
    large: {
      'first-option': '< 15 milionów',
      'second-option': '15 - 25 milionów',
      'third-option': '25 - 35 milionów',
      'fourth-option': '35+ milionów',
    },
    massive: {
      'first-option': '< 60 milionów',
      'second-option': '60 - 80 milionów',
      'third-option': '80 - 100 milionów',
      'fourth-option': '100+ milionów',
    },
  },
  'shape-round': {
    title: 'Jaki jest kształt {{countryName}}',
    sponsored: 'Sponsorowane przez',
    like: 'Podoba Ci się ta runda?',
    try: 'Spróbuj',
  },
  'capital-flag': {
    title: 'Wybierz flagę stolicy kraju {{country}}: {{city}}',
  },
  'emblem-round': {
    title: 'Wybierz godło narodowe {{countryName}}',
  },
  border: {
    title: 'Wybierz flagę kraju sąsiadującego z {{countryName}}',
  },
  settings: {
    title: 'Ustawienia',
    'distance-unit': 'Jednostka odległości',
    theme: 'Motyw',
    language: 'Język',
    'difficulty-modifiers': 'Modyfikatory trudności',
    grayscale: 'Flaga w odcieniach szarości',
  },
  stats: {
    title: 'Statystyki',
    played: 'Rozegrane',
    win: 'Procent wygranych',
    currentStreak: 'Obecna seria',
    maxStreak: 'Maksymalna seria',
    guessDistribution: 'Rozkład zgadywania',
    donate: '🇺🇦 Wspomóż Ukrainę ❤️',
  },
  'how-to-play': {
    title: 'Jak grać',
    guess: 'Zgadnij',
    'number-guesses': 'w 6 próbach.',
    'valid-country':
      'Każda odpowiedź musi być prawidłowym krajem lub terytorium.',
    'after-guess':
      'Po każdej próbie zobaczysz odległość, kierunek i bliskość Twojej odpowiedzi do lokalizacji docelowej.',
    examples: 'Przykłady',
    'your-guess': 'Twoja odpowiedź',
    is: 'jest',
    away: 'od lokalizacji docelowej, znajduje się na północnym wschodzie i bliskość wynosi tylko 32%, ponieważ jest dość daleko.',
    'second-guess': 'Twoja druga odpowiedź',
    'getting-closer':
      'jest bliżej! {{distance}} odległość, kierunek południowo-wschodni i 83% bliskości!',
    'next-guess': 'Następna odpowiedź,',
    'right-guess': 'to jest lokalizacja! Gratulacje',
    'new-game': 'Nowa gra',
    available: 'będzie dostępna codziennie!',
    suggestions: 'Masz pytania lub sugestie?',
    check: 'Sprawdź',
    faq: 'Flagle FAQ',
    made: 'Stworzone przez',
    ads: 'Reklamy od',
    privacy: 'Polityka prywatności',
    feedback: 'Opinie',
    hiring: 'Poszukujemy pracowników',
  },
  faq: {
    'when-new-game-available': {
      question: 'Kiedy dostępna jest nowa gra?',
      answer:
        'Nowa gra jest dostępna codziennie o godzinie 0:00 czasu lokalnego Twojego urządzenia!',
    },
    'how-earn-bonus-round': {
      question: 'Jak zdobyć dostęp do kolejnej rundy bonusowej?',
      answer:
        'Aby uzyskać dostęp do pierwszej rundy bonusowej (kształt), wystarczy poprawnie odgadnąć kraj.',
    },
    'why-data-incorrect': {
      question: 'Dlaczego dane są nieprawidłowe lub nieaktualne?',
      answer:
        'Staramy się aktualizować nasze dane tak szybko i dokładnie, jak to możliwe. Wiemy, że granice się zmieniają, stolice są przenoszone, a mapy stają się nieaktualne. Wszelkie błędy są całkowicie niezamierzone i nie są wyrazem politycznym. Doceniamy Twoje uwagi i poprawimy błędy, gdy tylko je zauważymy. Z czasem błędów będzie coraz mniej.',
    },
    'green-yellow-squares': {
      question: 'Co oznaczają zielone i żółte kwadraty?',
      answer:
        'Odpowiadają procentowi bliskości:<br><br>• 🟩 = 20%<br>• 🟨 = 10%',
    },
    'proximity-percent': {
      question: 'Co to jest procent bliskości?',
      answer:
        'To inny sposób przedstawienia odległości.<br><br>Jeśli Twoje przypuszczenie znajduje się po przeciwnej stronie świata, otrzymasz 0%.<br>Jeśli jest dokładnie na celu, otrzymasz 100%!',
    },
    'how-distances-computed': {
      question: 'Jak obliczane są odległości?',
      answer:
        'Wyświetlane odległości odpowiadają odległościom między środkami wybranego terytorium a celem.<br><br>Na przykład obliczona odległość między Stanami Zjednoczonymi a Kanadą wynosi około 2260 km, nawet jeśli mają wspólną granicę.<br><br>Dwa powody tego wyboru:<br><br>• Technicznie o wiele łatwiej jest obliczyć odległość w ten sposób, zamiast używać najbliższych granic.<br><br>• Dzięki tym informacjom możesz wywnioskować informacje o wielkości docelowego kraju.',
    },
    'why-not-valid-answer': {
      question: 'Dlaczego dany kraj nie jest poprawną odpowiedzią?',
      answer:
        'Używam standardu ISO 3166-1 jako odniesienia dla listy kodów krajów.<br><br>Na przykład Szkocja nie jest poprawną odpowiedzią, ponieważ według tego standardu kraj ten jest częścią Zjednoczonego Królestwa.',
    },
    'how-countries-picked': {
      question:
        'Jak wybierane są kraje i terytoria? Co się stanie, gdy wszystkie kraje zostaną wybrane?',
      answer:
        'Każdego dnia losowo wybierany jest kraj lub terytorium!<br><br>Więc każdego dnia będzie wybierany losowy, na zawsze!<br><br>Dodano dwie zasady:<br><br>• Nie wybiera kraju ani terytorium o powierzchni mniejszej niż 5000 km², jeśli w ciągu ostatnich 7 dni wybrano już jeden.<br>• Nie wybiera kraju, który został wybrany w ciągu ostatnich 100 dni.<br>• Nie wybiera kraju z tego samego kontynentu co wczoraj (dotyczy tylko Afryki i Azji).',
    },
    'why-ads': {
      question: 'Dlaczego widzę reklamy na moim urządzeniu?',
      answer:
        'Używamy reklam, aby pokryć koszty rozwoju nowych funkcji, a także hostingu i innych wydatków. Z czasem @teuteuf otrzymał wiele sugestii i próśb, których nie mógł spełnić. Chcemy dostarczyć Ci więcej funkcji i bardziej zabawną rozgrywkę. Naszym planem jest stworzenie oficjalnej aplikacji mobilnej, a także niezliczonych funkcji bonusowych, aby Flagle było najzabawniejszą grą geograficzną w internecie!',
    },
  },
  account: {
    title: 'Konto',
    loggedIn: {
      hi: 'Cześć',
      text: 'Teraz przechowujemy Twoje statystyki po każdej grze. To nie tylko chroni twoje postępy, ale także umożliwia płynną rozgrywkę na wielu urządzeniach.',
      learnMore: 'Ucz się więcej',
      signOut: 'Wyloguj się',
      manageAccount: 'Zarządzać kontem',
    },
    loggedOut: {
      text: 'Załóż konto Teuteuf Games lub zaloguj się, aby zacząć zapisywać statystyki gier i grać na wielu urządzeniach. Chcesz poznać wszystkie korzyści?',
      discoverMoreHere: 'Dowiedz się więcej tutaj',
      signUp: 'Zapisać się',
      login: 'Zaloguj sie',
      loginWith: 'Zaloguj się przez {{platform}}',
    },
  },
};
